let currentURL = window.location.href;
if (window.location.pathname === '/') {
  currentURL += 'index.html';
}

/**
 * Функція для створення розкриваючихся елементів
 * @param {string} selector - CSS-селектор для вибору елементів
 * @param {string} tag - HTML-тег наступного елемента, який потрібно знайти
 */
function makeExpandable (selector: string, tag: string) {
  let H3 = document.querySelectorAll(selector);
  for (const elem of H3) {
    elem.addEventListener('click', (e) => {
      const h3 = e.target as HTMLHeadingElement;
      h3.classList.toggle('expand');

      let next: ChildNode = h3.nextSibling!;
      while (next?.nodeName !== tag.toUpperCase()) {
        next = next.nextSibling!;
      }

      (next as HTMLElement).classList.toggle('expand');
    });
  }
}

function setMessage(data: string[]) {
  const content = document.getElementById('message-content');
  content!.innerHTML = data.map(l => `<p>${l}</p>`).join('\n'); 
  const bell = document.getElementById('bell');
  bell!.style.display ="block";
}


/** 
 * Виконуємо при завантаженні кожної сторінки 
 */
function onDocReady () {
  makeExpandable('.expand-ul h3', 'ul');
  makeExpandable('.expand-p h2', 'h3');

  // Hidden ul
  const list = document.querySelectorAll('.header-mob-inner ul > li');

  for (let item of list) {
    const selectURL = (item.firstChild as HTMLLinkElement).href;

    if (selectURL === currentURL) {
      item.classList.add('active');
    }
  }

  // Burger
  const burger = document.querySelector('.burger-hover');
  // console.log('burger', burger);
  burger?.addEventListener('click', (e) => {
    document.body.classList.toggle('menu-open');
    
  });

  // Close button
  const closeButton = document.getElementById('close-button');

  closeButton?.addEventListener('click', closeMessage);
  document.addEventListener('keydown', handleKeyDown);

  function closeMessage() {
    document.body.classList.remove('message-open');
    sessionStorage.setItem('hideMessage', 'true');
  }

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      closeMessage();
    }
  }


  // Close button
  // const closeButton = document.getElementById('close-button');
  // closeButton?.addEventListener('click', (e) => {
  //   document.body.classList.remove('message-open');
  //   sessionStorage.setItem('hideMessage', 'true');
  // });


  // Message
  setTimeout(() => { 
    if (!sessionStorage.getItem('hideMessage')) {
      fetch(`${location.hash}\message.json?${Math.random()}}`)
        .then(response => response.text())
        .then(text => {
            const data = JSON.parse(text);
            sessionStorage.setItem('contentMessage', text);

            setMessage(data);
            document.body.classList.add('message-open');
          })
        .catch((err) => {
          // console.log('load message', err);
          sessionStorage.setItem('hideMessage', 'true');
        });

    }
    else {
      if (sessionStorage.getItem('contentMessage')) {
        setMessage(JSON.parse(sessionStorage.getItem('contentMessage')!));
        bell!.style.display ="block";
      }
    }
  }, 1000);

  // Bell
  const bell = document.getElementById('bell');
  bell?.addEventListener('click', ()=>{
    sessionStorage.removeItem('hideMessage');
    document.body.classList.add('message-open');
  });
}

document.addEventListener('DOMContentLoaded', onDocReady);

//
